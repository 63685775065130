<template>
  <div
    class="account relative"
    data-testid="accountDesktopMenu"
    @mouseenter="openMenu"
    @mouseleave="closeMenu"
  >
    <div
      class="flex cursor-pointer select-none items-center text-base text-white gap-x-1 w-fit font-bold"
      @click="toggle"
    >
      <span>Account</span>
      <IconUser class="text-s-900 size-5" />
    </div>

    <!-- Submenu -->
    <transition
      enter-active-class="transition duration-300"
      leave-active-class="transition duration-500"
      enter-class="opacity-0"
      leave-to-class="opacity-0"
    >
      <div v-if="isOpen" data-testid="navigation-item-container" class="menu">
        <div v-for="group in menu.groups" :key="`${menu.name}_${group.name}`">
          <div class="block w-max">
            <div class="inline-block w-full">
              <h4
                class="border-p-500 font-montserrat text-s-900 mb-3 border-b-[3px] pb-3 text-base font-semibold uppercase leading-5"
              >
                {{ group.name }}
              </h4>

              <div
                v-for="child in group.children"
                :key="`${group.name}_${child.name}`"
              >
                <a
                  class="font-montserrat hover:bg-s-200 mb-1.5 flex cursor-pointer items-start justify-between rounded-lg px-1 text-base font-normal leading-5"
                  :class="child.extraClasses"
                  :data-testid="child.dataTestId"
                  @click.prevent="handleClick(child)"
                >
                  {{ child.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconUser from '@/assets/icons/User.svg'
import { isTouchDevice } from '~/utils/devices'

export default {
  components: {
    IconUser,
  },

  props: {
    subMenu: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      menu: {
        name: 'Account',
        open: false,
        groups: [
          {
            name: 'Your Account',
            parent: 'Account',
            children: [
              ...this.subMenu,
              {
                name: 'Logout',
                href: '',
                extraClasses: 'mt-10',
                onClick: 'logout',
                dataTestId: 'logOutDesktopBtn',
              },
            ],
          },
        ],
      },
    }
  },
  computed: {
    isOpen() {
      return this.menu.groups && this.menu.groups.length && this.menu.open
    },
  },
  methods: {
    toggle() {
      this.menu.open = !this.menu.open
    },
    openMenu() {
      // In touch devices, we return early to avoid conflict with the logic implemented in the "hover" state
      if (isTouchDevice()) return
      this.menu.open = true
    },
    closeMenu() {
      this.menu.open = false
    },

    logout() {
      this.$bmAuth.logout()
      this.$router.push('/')
    },

    handleClick({ href, onClick }) {
      onClick ? this[onClick]() : this.$router.push(href)
    },
  },
}
</script>

<style scoped>
.account::before {
  @apply absolute left-0 top-full h-6 w-full bg-transparent;

  content: '';
}

.menu {
  @apply text-s-900 absolute right-0 z-50 mt-4 rounded-lg bg-white p-8 2xl:left-1/4 2xl:-translate-x-1/2;

  min-width: 250px;
  box-shadow: 3px 3px 10px rgba(0 0 0 / 25%);
}

.menu::before {
  @apply absolute -top-2 right-12 size-4 rotate-45 bg-white 2xl:left-1/2 2xl:-translate-x-1/2;

  content: '';
  z-index: 100;
  box-shadow: -3px -5px 10px -1px rgba(0 0 0 / 10%);
}
</style>
