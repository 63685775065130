<template>
  <div
    class="bg-s-900 text-white flex items-center justify-center w-full py-2 px-0 md:px-4 lg:pl-10 lg:pr-6 2xl:px-0"
  >
    <div
      class="max-w-content-container relative flex items-center justify-between gap-2 flex-1"
    >
      <div
        class="relative flex h-5 min-w-0 grow transition-opacity text-sm"
        :class="loading ? 'opacity-0' : 'opacity-100'"
      >
        <button
          class="flex w-5 items-center justify-center"
          @click="moveToRight"
        >
          <Icon name="angle-left" />
        </button>
        <div
          id="ticker-container"
          class="relative flex grow overflow-hidden scroll-smooth pr-16 font-semibold"
          @mouseover="pauseAnimation"
          @mouseleave="resumeAnimationDelay"
        >
          <div class="indexes-container absolute flex items-center gap-8 pr-8">
            <TickerIndex
              v-for="index in indexes"
              :key="index.name"
              :index="index"
              @resume-animation="resumeAnimationDelay"
              @pause-animation="pauseAnimation"
              @mounted="isFirstIndexesContainerMounted = true"
            />
          </div>
          <div class="indexes-container absolute flex items-center gap-8 pr-8">
            <TickerIndex
              v-for="index in indexes"
              :key="index.name"
              :index="index"
              @resume-animation="resumeAnimation"
              @pause-animation="pauseAnimation"
              @mounted="isSecondIndexesContainerMounted = true"
            />
          </div>
        </div>

        <button
          class="flex w-5 items-center justify-center"
          @click="moveToLeft"
        >
          <Icon name="RightAngle" />
        </button>
      </div>

      <div class="flex items-center justify-between gap-x-5">
        <NuxtLink
          to="/lithium-ion-battery-raw-material-index"
          class="flex items-center gap-2"
        >
          <Icon name="Curves" class="h-3 w-3 xl:h-4 xl:w-4" />
          <span class="text-sm"> Lithium ion Battery Raw Material Index </span>
        </NuxtLink>
        <UserOptions class="hidden lg:block" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import debounce from 'lodash/debounce'
import Icon from '@/components/common/Icon.vue'
import UserOptions from '~/components/UserOptions.vue'
import ClientDebounce from '@/utils/client-debounce'
import useAuthentication from '~/composables/useAuthentication'
import useIndexesStore from '~/stores/indexes'

const { $restClient } = useNuxtApp()
const indexesStore = useIndexesStore()
const { authenticationChanged } = useAuthentication()

const loading = ref(true)
const isFirstIndexesContainerMounted = ref(false)
const isSecondIndexesContainerMounted = ref(false)
const animation = ref(null)
const INTERVAL = 0.1
const INTERVAL_DURATION = 0.3
const debounceResumeAnimation = new ClientDebounce()

const indexes = computed(() => indexesStore.indexes)

const isTickerContainerMounted = computed(
  () =>
    isFirstIndexesContainerMounted.value &&
    isSecondIndexesContainerMounted.value,
)

const resumeAnimation = () => animation.value?.resume()

const pauseAnimation = () => {
  animation.value?.pause()
  debounceResumeAnimation.clear()
}

const resumeAnimationDelay = debounce(resumeAnimation, 1000)

const startAnimation = () => {
  const speed = 0.03
  const INDEXES_CONTAINER_CLASS = '.indexes-container'
  const indexesContainer = document.querySelectorAll(INDEXES_CONTAINER_CLASS)
  const { scrollWidth } = indexesContainer[0]
  const totalWidth = scrollWidth
  const duration = Math.abs(scrollWidth * speed)
  gsap.set(INDEXES_CONTAINER_CLASS, {
    x: (i) => i * scrollWidth,
  })
  animation.value = gsap.to(INDEXES_CONTAINER_CLASS, {
    delay: 2,
    duration,
    ease: 'none',
    x: `-=${totalWidth}`,
    modifiers: {
      x: gsap.utils.unitize((x) => parseFloat(x) % totalWidth),
    },
    repeat: -1,
  })
  loading.value = false
}

const moveToLeft = () => {
  pauseAnimation()
  gsap.to(animation.value, {
    duration: INTERVAL_DURATION,
    progress: `+=${INTERVAL}`,
  })
  resumeAnimationDelay()
}

const moveToRight = () => {
  pauseAnimation()
  const currentProgress = animation.value?.progress()

  if (currentProgress - INTERVAL < 0) {
    /* this code is to avoid a weird animation
     * basically when the user click on the left arrow faster
     * This animation works by dividing the duration
     * according to the progress or position until it reaches 0.
     *
     * E.g. it takes 3s to animate 300px.
     * So, to animate 200px we need to calculate that duration
     * by dividing that width by the total duration of the animation
     * in this example, 3 seconds.
     */
    const durationToZero = currentProgress / INTERVAL_DURATION
    const remainingDuration = INTERVAL_DURATION - durationToZero
    const remainingProgress = INTERVAL - currentProgress

    gsap
      .timeline()
      .to(animation.value, {
        duration: durationToZero,
        progress: '0',
      })
      .to(animation.value, {
        duration: 0,
        progress: '1',
      })
      .to(animation.value, {
        duration: remainingDuration,
        progress: `${1 - remainingProgress}`,
      })
  } else {
    gsap.to(animation.value, {
      duration: INTERVAL_DURATION,
      progress: `-=${INTERVAL}`,
    })
  }
  resumeAnimationDelay()
}

const getTickerIndexes = () => {
  $restClient
    .getTickerIndexes()
    .then((response) => {
      indexesStore.setIndexesStates(response)
    })
    .catch((error) => {
      logError(error)
    })
}

watch(isTickerContainerMounted, (newVal, oldVal) => {
  if (newVal && !oldVal) {
    debounce(startAnimation, 1000)()
  }
})

watch(authenticationChanged, async () => {
  getTickerIndexes()
})
</script>
