<template>
  <div id="navbar" class="fixed top-0 z-50 w-full lg:sticky">
    <TheTicker class="hidden md:flex" />
    <div class="bg-s-50 lg:border-b-s-200 w-full lg:border-b">
      <div
        class="max-w-content-container mx-auto w-full px-0 lg:pl-10 lg:pr-6 2xl:px-0"
      >
        <NavigationSection :key="route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheTicker from '@/components/TheTicker.vue'
import NavigationSection from '@/components/navigation/Section.vue'
import { useScreenSize } from '@/composables/useScreenSize'

const route = useRoute()
const { isTablet, isMobile } = useScreenSize()

const prevScrollPos = ref(0)

const scrollFunction = () => {
  const currentScrollPos = window.scrollY
  const navbar = document.getElementById('navbar')
  const navbarHeight = navbar.offsetHeight

  if (
    prevScrollPos.value < currentScrollPos &&
    currentScrollPos > navbarHeight
  ) {
    navbar.style.top = `-${navbarHeight}px`
  } else if (prevScrollPos.value > currentScrollPos) {
    navbar.style.top = '0'
  }

  prevScrollPos.value = currentScrollPos
}

onMounted(() => {
  if (!isTablet.value && !isMobile.value) return
  window.onscroll = scrollFunction
})
</script>

<style scoped>
#navbar {
  transition: top 0.3s ease;
}
</style>
