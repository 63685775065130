<script setup lang="ts">
import SourceIcon from 'assets/icons/sidebar/Terminal.svg'
const { $config } = useNuxtApp()

const sourceLink = $config.public.sourceUrl
</script>

<template>
  <NuxtLink
    :href="sourceLink"
    target="_blank"
    class="flex items-center gap-x-1 text-black border-2 rounded border-p-500 py-2 px-4 font-bold leading-none w-fit h-fit min-h-[42px]"
    external
  >
    <SourceIcon class="size-5" />
    <span>Visit Source</span>
  </NuxtLink>
</template>
