<script setup lang="ts">
import { SIGN_IN } from '~/constants/services/constants'
import useAuthStore from '~/stores/auth'
import { ACCOUNT_MENU } from '~/constants/main-menu'

const authStore = useAuthStore()

const authenticated = computed(() => authStore.authenticated)
</script>

<template>
  <button
    v-if="!authenticated"
    data-testid="signInDesktopBtn"
    class="font-bold text-base sign-in-nav-button"
    @click="$modal.open('signIn')"
  >
    {{ SIGN_IN }}
  </button>
  <NavigationDesktopAccountMenu v-else :sub-menu="ACCOUNT_MENU" />
</template>
