<template>
  <div
    class="w-full grid grid-cols-1 grid-rows-2 xl:flex justify-between items-center gap-x-2"
  >
    <div class="col-span-3 xl:col-span-1">
      <NuxtLink to="/" class="flex pt-2 pb-5 w-fit">
        <IconBenchmarkLogo class="shrink-0" />
      </NuxtLink>
    </div>

    <div class="flex items-center gap-x-1 h-full justify-end w-full xl:w-fit">
      <NavigationItem
        v-for="(menu, index) in computedMenuList"
        :key="getKey(menu)"
        :data="menu"
        :is-selected-group="activeMenuIndex === index"
        data-testid="top-navigation-item"
      />
    </div>
    <div class="flex gap-x-3 justify-end">
      <SourceButton />
      <ButtonContactUs class="py-2 px-4 leading-none" />
    </div>
  </div>
</template>
<script>
import IconBenchmarkLogo from '~/assets/icons/BenchmarkLogoBlack.svg'
import NavigationItem from '~/components/navigation/Item.vue'
import SourceButton from '~/components/navigation/SourceButton.vue'
import { DESKTOP_MENU } from '~/constants/main-menu'
import ButtonContactUs from '~/components/shared/common/ButtonContactUs.vue'

export default {
  components: {
    SourceButton,
    ButtonContactUs,
    NavigationItem,
    IconBenchmarkLogo,
  },
  data() {
    return {
      menuList: DESKTOP_MENU,
      sourceLink: this.$config.public.sourceUrl,
    }
  },
  computed: {
    /**
     * Adjust the source link using the public config. We need to adjust
     * the link to source here because of the need to access the env variables
     * from the nuxt.config.ts file.
     */
    computedMenuList() {
      return this.menuList.map((menu) => {
        if (menu.name.toLowerCase() !== 'services') return menu

        return {
          ...menu,
          groups: menu.groups.map((group) => {
            if (group.name.toLowerCase() !== 'subscriptions') return group

            return {
              ...group,
              children: group.children.map((child) => {
                if (child.name.toLowerCase() !== 'source') return child

                return {
                  ...child,
                  href: this.$config.public.sourceUrl,
                }
              }),
            }
          }),
        }
      })
    },

    activeMenuIndex() {
      const { fullPath } = this.$route

      const NEWSLETTERS_PATH = [
        'newsletter-signup',
        'price-spotlight-signup',
        'industry-reports',
        'policy-newsletter',
        'copper-weekly',
      ]

      if (fullPath === '/') return

      const index = this.computedMenuList.findIndex((item) => {
        if (item.groups.length === 0) return item.href === fullPath

        const paths = fullPath.split('/').filter((path) => path)
        return item.groups
          .filter(
            (group) =>
              (group.href && paths.includes(group.href.replace('/', ''))) ||
              group.children?.length > 0,
          )
          .map((group) => group.children)
          .map((children) =>
            children.map((child) => child?.children ?? child).flat(),
          )
          .flat()
          .some((subItem) => {
            const market = subItem?.href?.trim()?.split('/')?.at(1)
            return paths.includes(market) ?? NEWSLETTERS_PATH.includes(fullPath)
          })
      })

      if (index >= 0) this.getActiveSubItem(this.computedMenuList[index])
      return index
    },
  },

  methods: {
    getActiveSubItem(menu) {
      this.$store.commit('breadcrumb/clearBreadcrumb')
      if (menu.name?.toLowerCase() !== 'markets') {
        this.$store.commit('breadcrumb/addItemBreadcrumb', menu.name)
      }

      if (menu.groups.length === 0) return

      const activeSubMenu = this.$store.getters['menu/getActiveSubMenu']
      const activeMenu = this.$store.state.menu.activeMenu

      if (activeMenu !== null) {
        this.$store.commit('breadcrumb/addItemBreadcrumb', activeMenu.label)
      }

      if (!activeSubMenu) return
      if (
        !(!this.$bmAuth.isAuthenticated() && activeSubMenu === 'Chart builder')
      ) {
        this.$store.commit('breadcrumb/addItemBreadcrumb', activeSubMenu)
      }
    },
    getKey(menu) {
      if (!menu.groups.length) return menu.name

      const childrenLength = menu.groups.flatMap(
        (group) => group.children,
      ).length

      return `${menu.name}_${childrenLength}`
    },
  },
}
</script>
