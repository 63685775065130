<template>
  <div class="flex w-full items-center justify-between">
    <div class="px-4">
      <IconCloseMenu
        v-if="$store.state.modal.mobileMenuOpen"
        class="size-6 cursor-pointer"
        data-testid="menu-mobile-close-icon"
        @click="manageMenuMobileClick"
      />
      <IconHamburgerMenu
        v-else
        class="size-6 cursor-pointer"
        data-testid="menu-mobile-icon"
        @click="manageMenuMobileClick"
      />
    </div>
    <NuxtLink to="/" class="py-3">
      <IconBenchmarkLogoMobile />
    </NuxtLink>
    <div
      v-if="!authenticated"
      class="bg-p-100 cursor-pointer text-xs font-semibold py-3 px-4"
      @click="manageSingInClick"
    >
      {{ SIGN_IN }}
    </div>
    <div
      v-else
      class="flex cursor-pointer select-none items-center justify-end py-3 px-4"
      @click="manageMobileAccountClick"
    >
      <IconUser class="h-6 w-6" />
    </div>

    <Teleport to="body">
      <NavigationMobileMenu
        :open="$store.state.modal.mobileMenuOpen"
        :menu="MOBILE_MENU"
        modal-name="mobileMenu"
      />
      <NavigationMobileMenu
        :open="$store.state.modal.mobileAccountMenuOpen"
        :menu="ACCOUNT_MENU"
        initial-menu-title="Account"
        modal-name="mobileAccountMenu"
      />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import IconBenchmarkLogoMobile from '~/assets/icons/BenchmarkLogoBlackMobile.svg'
import IconCloseMenu from '~/assets/icons/Close.svg'
import IconHamburgerMenu from '~/assets/icons/Hamburger.svg'
import IconUser from '~/assets/icons/User.svg'
import { SIGN_IN } from '~/constants/services/constants'
import { useStore } from 'vuex'
import useAuthStore from '~/stores/auth'
import NavigationMobileMenu from '~/components/navigation/MobileMenu.vue'
import { ACCOUNT_MENU, MOBILE_MENU } from '~/constants/main-menu'
import { useScreenSize } from '~/composables/useScreenSize'

const authStore = useAuthStore()
const { isNotDesktop } = useScreenSize()

const authenticated = computed(() => authStore.authenticated)

const store = useStore()
const { $modal } = useNuxtApp()

const manageMenuMobileClick = () => {
  if (store.state.modal.mobileMenuOpen) {
    $modal.close('mobileMenu')
  } else {
    $modal.open('mobileMenu')
    $modal.close('mobileAccountMenu')
  }
}
const manageMobileAccountClick = () => {
  if (store.state.modal.mobileAccountMenuOpen) {
    $modal.close('mobileAccountMenu')
  } else {
    $modal.open('mobileAccountMenu')
    $modal.close('mobileMenu')
  }
}
const manageSingInClick = () => {
  if (store.state.modal.mobileMenuOpen) {
    $modal.close('mobileMenu')
  }
  $modal.open('signIn')
}

watch(isNotDesktop, (value) => {
  if (!value) {
    $modal.close('mobileMenu')
    $modal.close('mobileAccountMenu')
  }
})
</script>
